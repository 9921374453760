{
  "header": {
    "mint": "Mint",
    "mints": "Fresh Mints",
    "rankings": "Top",
    "collections": "Top Collections",
    "profiles": "Top Profiles",
    "activity": "Activity",
    "closeMainMenu": "Close main menu",
    "openMainMenu": "Open main menu",
    "search": "Search...",
    "noResults": "No results found",
    "nothingFound": "Nothing found for this search term. Please try again.",
    "login": "Login",
    "myProfiles": "My Profiles",
    "logout": "Logout"
  },
  "mintGarden": "MintGarden",
  "home": {
    "heroTitle": "Embrace decentralized and trustless NFTs",
    "heroSubtitle": "Create, collect and trade NFTs and digital artifacts on Chia",
    "exploreAndMint": "Explore and mint NFTs in the Chia ecosystem.",
    "exploreCollections": "Explore collections",
    "startMinting": "Start Minting",
    "weeklyTopCollections": "Weekly Top Collections",
    "weeklyTopCreators": "Weekly Top Creators",
    "exploreAllCollections": "Explore all collections",
    "exploreAllCreators": "Explore all creators",
    "activeAuctions": "Active and Upcoming Auctions",
    "notableCollections": "Notable Collections",
    "supporters": "Supporters",
    "allInOneNFT": "All-in-One NFT Platform on Chia",
    "embraceNFTs": "Embrace decentralized and trustless NFTs",
    "experiencePower": "Experience the power of Chia NFTs with MintGarden's comprehensive suite of tools, from minting with the MintGarden Studio to engaging communities with the Discord Bot, and exploring the Chia NFT landscape through the MintGarden website.",
    "createAndMint": "Create and mint Chia NFTs.",
    "mintDescription": "Seamlessly mint single or bulk Chia NFTs directly to your wallet, assign DIDs for provenance, and create offers for Dexie.",
    "engageCommunity": "Engage your NFT community.",
    "communityDescription": "Keep your community updated on secondary market trades, and secure exclusive Discord channels for verified NFT owners.",
    "featureExploreCollections": "Explore Chia NFT collections.",
    "collectionsDescription": "Discover collections, connect with creators and collectors, and stay updated on on-chain activities and decentralized exchange offers."
  },
  "collections": {
    "collection": "Collection",
    "volume": "Volume",
    "floorPrice": "Floor Price",
    "numTrades": "# Trades",
    "numItems": "# Items",
    "topCollections": "Top Collections",
    "days7": "7 Days",
    "days30": "30 Days",
    "allTime": "All Time"
  },
  "collection": {
    "by": "By",
    "mint": "Mints",
    "items": "Items",
    "activity": "Activity",
    "trades": "Trades",
    "floor": "Floor",
    "totalVolume": "Total Volume",
    "recent": "Recent",
    "lowestPrice": "Lowest Price",
    "highestPrice": "Highest Price",
    "Offer: Low to High": "Offer: Low to High",
    "Offer: New to Old": "Offer: New to Old",
    "Rank: Rarest to Common": "Rank: Rarest to Common",
    "Recently Moved": "Recently Moved",
    "Oldest to Newest": "Oldest to Newest",
    "price": "Price",
    "highest_bid": "Highest Bid",
    "reserve": "Reserve",
    "no_bid": "No Bid",
    "starts_in": "Starts in",
    "ends_in": "Ends in",
    "shareWithHandle": "Check out this collection by {'@'}{handle} on {'@'}MintGarden_io! \uD83C\uDF31\n\n{url}",
    "shareWithoutHandle": "Check out this collection on {'@'}MintGarden_io! \uD83C\uDF31\n\n{url}"
  },
  "mint": {
    "mintgarden_studio": "MintGarden Studio",
    "email_address": "Email address",
    "enter_your_email": "Enter your email",
    "submitted": "Submitted!",
    "request_access": "Request Access",
    "minting_made_simple": "Minting made simple",
    "description": "is a desktop application that makes it easy for artists to mint their work as NFTs on the Chia blockchain. Download it now and mint your first NFT!",
    "share_creations": "Share your creations with the world.",
    "stay_in_control": "Stay in control",
    "stay_in_control_description": "MintGarden Studio creates NFTs directly from your local machine, no third parties involved. So your private keys stay with you all the time.",
    "so_simple": "So simple you'll love it",
    "so_simple_description": "We handle all the tricky parts like hashing, metadata formatting and uploading your images to IPFS so you don't have to.",
    "bulk_minting": "Bulk Minting",
    "bulk_minting_description": "By minting 25 NFTs per block, MintGarden Studio allows you to get large collections to your community faster.",
    "random_minting": "Random Minting",
    "random_minting_description": "Mint NFTs on demand after receiving payment to ensure a fair and random distribution of your collection.",
    "get_early_access": "Get early access to MintGarden Studio.",
    "early_access_description": "We will invite a small number of users to try out MintGarden Studio before it is being released to the public. Enter your email to get access."
  },
  "activity": {
    "ago": " ago",
    "mint": "Mint",
    "minted": "Minted",
    "trade": "Trade",
    "traded": "Traded",
    "transfer": "Transfer",
    "transferred": "Transferred",
    "burn": "Burn",
    "burned": "Burned",
    "filter": "Filter",
    "filters": "Filters",
    "close_menu": "Close menu",
    "event_type": "Event Type",
    "collections": "Collections",
    "asset": "Asset",
    "price": "Price",
    "sender": "Sender",
    "recipient": "Recipient",
    "time": "Time"
  },
  "details": {
    "attributes": "Attributes",
    "provenance": "Provenance",
    "title": "Details",
    "mintHeight": "Mint Height",
    "series": "Series",
    "edition": "Edition",
    "perpetualRoyalties": "Perpetual Royalties",
    "openRarityRank": "OpenRarity Rank",
    "license": "License",
    "licenseMismatch": "License (Hash Mismatch)",
    "noLicense": "No License / All Rights Reserved",
    "inspectOnSpacescan": "Inspect on spacescan.io",
    "openOriginalDataURI": "Open original data URI",
    "openOriginalMetadataURI": "Open original metadata URI",
    "creator": "Creator",
    "owner": "Owner",
    "burned": "Burned",
    "collection": "Collection",
    "listings": "Listings",
    "bids": "Bids",
    "noOffers": "No Offers",
    "uploadFirstOffer": "Offer this NFT for sale or make a bid now.",
    "uploadOffer": "Make offer",
    "price": "Price",
    "activeSince": "Active since",
    "actions": "Actions",

    "auction": "Auction",
    "auction_details": "Details",
    "auction_ended": "Auction ended",
    "highest_bid": "Highest bid",
    "no_bid": "No bid",
    "no_bid_reserve": "No bid, min. {price} XCH",
    "auction_ends_in": "Auction ends in",
    "overtime_ends_in": "Overtime ends in",
    "auction_disclaimer": " This auction is conducted by the current owner, not by MintGarden.\nThey will accept the winning bid manually after the auction ends."
  },
  "offers": {
    "copyOffer": "Copy Offer",
    "downloadOffer": "Download Offer",
    "takeOffer": "Take Offer",
    "connectWallet": "Connect Wallet",
    "offering": "Offering",
    "requesting": "Requesting",
    "offer": "Offer",
    "plus": "Plus",
    "including": "Including",
    "creator_royalties": "creator royalties"
  },
  "provenance": {
    "mintedByPre": "Created by ",
    "mintedByPost": "",
    "transferredToPre": "Transferred to ",
    "transferredToPost": "",
    "acquiredByPre": "Acquired by ",
    "acquiredByPost": "",
    "burnedPre": "Burned",
    "burnedPost": "",
    "auctionStartedPre": "Auction started",
    "auctionStartedPost": "",
    "auctionEndedPre": "Auction ended",
    "auctionEndedPost": "at",
    "andOtherAddresses": "and {count} other addresses"
  },
  "profile": {
    "topCreators": "Top Creators",
    "share": "Share",
    "report": "Report",
    "featured": "Featured",
    "created": "Created",
    "collections": "Collections",
    "owned": "Owned",
    "activity": "Activity",
    "shareWithHandle": "Check out this profile by {'@'}{handle} on {'@'}MintGarden_io! \uD83C\uDF31\n\n{url}",
    "shareWithoutHandle": "Check out this profile on {'@'}MintGarden_io! \uD83C\uDF31\n\n{url}"
  },
  "address": {
    "address": "Address",
    "owned": "Owned",
    "activity": "Activity"
  },
  "footer": {
    "description": "Explore and mint NFTs in the Chia ecosystem.",
    "support": "Support",
    "documentation": "Documentation",
    "status": "Status",
    "legalnotice": "Legal Notice",
    "developers": "Developers",
    "api": "API",
    "blog": "Blog",
    "language": "Language",
    "allRightsReserved": "All rights reserved."
  },
  "register": {
    "registerTitle": "Register a new account",
    "or": "Or",
    "signIn": "sign into an existing account",
    "email": "Email address",
    "password": "Password",
    "passwordHint": "Min. 8 characters; letter, number and symbol",
    "verificationCode": "Verification Code",
    "welcome": "Welcome, {email}!",
    "redirecting": "Redirecting...",
    "redirectHelp": "Please click if not redirected automatically...",
    "confirm": "Confirm",
    "register": "Register",
    "profileCustomization": "Profile Customization",
    "profileCustomizationDescription": "Customize the appearance of your profiles (DIDs) on MintGarden, including the profile name and your avatar.",
    "socialVerification": "Social Verification",
    "socialVerificationDescription": "Connect your profiles (DIDs) to your social media accounts to increase your profiles credibility.",
    "notificationsOnBids": "Notifications on Bids",
    "notificationsOnBidsDescription": "Get notified when you receive a bid for one of your NFTs on any of the popular exchanges."
  },
  "login": {
    "resetAccount": "Reset your account",
    "signInAccount": "Sign in to your account",
    "or": "Or",
    "tryLogin": "try to log in",
    "registerNewAccount": "register a new account",
    "emailAddress": "Email address",
    "password": "Password",
    "verificationCode": "Verification Code",
    "forgotPassword": "Forgot your password?",
    "resetPassword": "Reset password",
    "signIn": "Sign In"
  },
  "user": {
    "myProfiles": "My Profiles",
    "loadingProfiles": "Loading profiles...",
    "claimAProfile": "Claim a profile",
    "claimAnotherProfile": "Claim another profile",
    "handle": "MintGarden Handle",
    "name": "Name",
    "impersonationWarning": "Impersonating another user will result in a permanent ban.",
    "biography": "Biography",
    "connectTwitter": "Connect X (aka Twitter) account",
    "twitterHandle": "X (aka Twitter) handle",
    "unlink": "Unlink",
    "connectDiscord": "Connect Discord account",
    "discordProfile": "Discord profile",
    "notificationPreferences": "Notification preferences",
    "beta": "BETA",
    "disableAll": "Disable all",
    "enableAll": "Enable all",
    "onNewBid": "You received a new bid",
    "onOwnNftForSale": "You listed an NFT for sale",
    "onOfferAccepted": "One of your NFTs was sold",
    "onBidCancelled": "A bid for one of your NFTs was cancelled",
    "onOwnForSaleCancelled": "You cancelled one of your sell offers",
    "save": "Save",
    "profileUpdateSuccess": "Profile successfully updated!",
    "claimProfile": "Claim profile",
    "profileClaimSuccess": "Profile claimed successfully!",
    "back": "Back",
    "verificationMethod": "How do you want to verify?",
    "walletConnect": "WalletConnect",
    "walletConnectDescription": "Sign a message using WalletConnect",
    "cliSignMessage": "Chia Command Line",
    "cliSignMessageDescription": "Sign a message using the Chia wallet CLI",
    "pawket": "Pawket",
    "pawketDescription": "Sign a message in the Pawket wallet",
    "signMessage": "Sign a message using WalletConnect",
    "connectChiaWallet": "Connect to your Chia wallet using WalletConnect and sign a message to prove ownership.",
    "waitingConfirmation": "Waiting for wallet confirmation...",
    "fetchProfiles": "Fetch profiles",
    "signMessageButton": "Sign message",
    "connectedTo": "Connected to",
    "wallet": "wallet.",
    "whichProfileClaim": "Which profile do you want to claim?",
    "syncWalletNote": "If you are missing a profile here, make sure the wallet is synced.",
    "fetchProfilesFail": "Failed to fetch your profiles. Please enter your DID manually.",
    "disconnect": "Disconnect",
    "connectWallet": "Connect Wallet"
  }
}
